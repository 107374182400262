// jQuery
require('jquery');
require('jquery-confirm/dist/jquery-confirm.min');

// Bootstrap
require('bootstrap');

// Selectize
require('@selectize/selectize');

// Flatpickr
require('flatpickr');
import monthSelect from 'flatpickr/dist/plugins/monthSelect/index';
window.monthSelect = monthSelect;

// TinyMCE
import tinymce from 'tinymce';

import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/quickbars';
